import React from "react";

import { Paragraph1 } from "baseui/typography";
import { StyledLink } from "baseui/link";

import Page from "../../components/internal-page";
import { HeadingLevel, Heading } from "baseui/heading";

const Partners = () => {
  return (
    <Page title="Партнеры Фонда" path="/fund/partners" parent="fund">
      <Paragraph1>
        Фонд "МКК Развитие" заключил партнерские соглашения с различными
        компаниями и индивидуальными предпринимателями для удобства своих
        клиентов.
      </Paragraph1>
      <HeadingLevel>
        <Heading>Поручительство гарантийного фонда</Heading>
        <Paragraph1>
          Региональная гарантийная организация{" "}
          <StyledLink
            href="http://icarh.ru/"
            target="_blank"
            rel="noopener noreferrer"
          >
            АО "РГО Архангельской области"
          </StyledLink>
        </Paragraph1>
      </HeadingLevel>
      <HeadingLevel>
        <Heading id={"valuation"}>Оценка имущества</Heading>
        <Paragraph1>
          ООО "Бизнес-оценка",{" "}
          <StyledLink
            href="http://biznes-ocenka29.ru/"
            target="_blank"
            rel="noopener noreferrer"
          >
            biznes-ocenka29.ru
          </StyledLink>
        </Paragraph1>
        <Paragraph1>
          ГУП АО "Фонд имущества и инвестиций",{" "}
          <StyledLink
            href="https://fondarh.ru/"
            target="_blank"
            rel="noopener noreferrer"
          >
            fondarh.ru
          </StyledLink>
        </Paragraph1>
        <Paragraph1>
          ООО "Респект",{" "}
          <StyledLink
            href="https://respect29.ru"
            target="_blank"
            rel="noopener noreferrer"
          >
            respect29.ru
          </StyledLink>
        </Paragraph1>
        <Paragraph1>
          ООО "РосОценка", г. Архангельск, ул. Тимме, д. 26, этаж 4, офис 7,
          тел. (8182) 471007, +7-9212467107, e-mail: roso5@rambler.ru
        </Paragraph1>
        <Paragraph1>
          ООО "БИГ", г. Архангельск, пр. Ломоносова, д. 135, офис 405, тел.
          (8182) 652454, +7-9600120931, e-mail: ooo.big29@yandex.ru
        </Paragraph1>
        <Paragraph1>
          Частнопрактикующий оценщик Котляренко Денис Александрович, тел.
          +7-9217206771, e-mail: deko78@mail.ru
        </Paragraph1>
        <Paragraph1>
          Частнопрактикующий оценщик Молодцова Валентина Николаевна, тел.
          +7-9642998070, e-mail: appraiser_strizh@mail.ru
        </Paragraph1>
        <Paragraph1>
          Частнопрактикующий оценщик Уварова Светлана Сергеевна, тел. (8182)
          431842, e-mail: 431842@mail.ru
        </Paragraph1>
        <Paragraph1>
          ООО "АльПрофи", г. Архангельск, наб. Северной Двины, д. 55, корп. А,
          офис 611, тел./факс (8182) 462223, e-mail: 8182462223@mail.ru
        </Paragraph1>
        <Paragraph1>
          ООО "Финансовая экспертиза", г. Архангельск, ул. Поморская, д. 34,
          корп. 1, вход №4, 1 этаж; тел. (8182) 652518, e-mail: vivat@finex29.ru
        </Paragraph1>
      </HeadingLevel>
      <HeadingLevel>
        <Heading id={"insurance"}>Страхование имущества</Heading>
        <Paragraph1>
          ПАО «Страховая акционерная компания «ЭНЕРГОГАРАНТ», Северо-Западный
          филиал, Архангельское отделение, 163000, г. Архангельск, пл. Ленина,
          д. 4, офис 409, 412, тел. (8182) 608171, e-mail:
          archangelsk@spb-garant.ru
        </Paragraph1>
        <Paragraph1>
          АО "Страховое общество газовой промышленности" (АО "СОГАЗ"),
          Архангельский филиал, 163069, г. Архангельск, пр-т Троицкий, д. 95,
          корп. 1, этаж 3, тел. (8182) 639005, e-mail: banev.vasily@sogaz.ru
        </Paragraph1>
      </HeadingLevel>
    </Page>
  );
};

export default Partners;
